import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";

const YardSaleReport = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "yardsale"));
        const registrationsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            items: Array.isArray(data.items)
              ? data.items
              : [data.items].filter(Boolean),
            sellerOptions: data.sellerOptions || {},
          };
        });
        setRegistrations(registrationsData);
      } catch (error) {
        console.error("Error fetching registrations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const formatItems = (items) => {
    if (!items) return "";
    if (typeof items === "string") return items;
    if (Array.isArray(items)) return items.join(", ");
    return JSON.stringify(items);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Yard Sale Registrations
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="registrations table">
          <TableHead>
            <TableRow>
              <TableCell>Seller Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone/Email</TableCell>
              <TableCell>Seller Option</TableCell>
              <TableCell>Booth Size</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Items</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((registration) => (
              <TableRow key={registration.id}>
                <TableCell>{registration.sellerCode || "N/A"}</TableCell>
                <TableCell>{registration.name || "N/A"}</TableCell>
                <TableCell>{registration.phoneOrEmail || "N/A"}</TableCell>
                <TableCell>
                  {registration.sellerOptions?.sellerOption || "N/A"}
                </TableCell>
                <TableCell>
                  {registration.sellerOptions?.boothSize || "N/A"}
                </TableCell>
                <TableCell>{registration.paymentType || "N/A"}</TableCell>
                <TableCell>{formatItems(registration.items)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default YardSaleReport;
