import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";

import { getFunctions, httpsCallable } from "firebase/functions";

import config from "../firebaseConfig";
import firebase from "firebase/compat/app";

firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
const functions = getFunctions();

export const CLUB_ID = "cola-stl";

export const logEvent = (eventName, eventParams) => {
  analytics.logEvent(eventName, eventParams);
};

export const updateProfile = async (member, updates) => {
  try {
    const memberRef = db.collection(`clubs/${CLUB_ID}/members`).doc(member.id);
    await memberRef.update({
      ...updates,
      lastupdate: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    const updatedMember = await memberRef.get();
    return updatedMember.data();
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getMemberOrders = async (memberId) => {
  try {
    const orders = await db
      .collection(`clubs/${CLUB_ID}/orders`)
      .where("memberId", "==", memberId)
      .get();
    const orderData = orders.docs
      .map((order) => ({
        id: order.id,
        ...order.data(),
      }))
      .sort((a, b) => {
        return a.createdate < b.createdate ? 1 : -1;
      });
    return orderData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

export const getOrCreateMember = async (user) => {
  const memberRef = db.collection(`clubs/${CLUB_ID}/members`).doc(user.uid);

  const memberSnapshot = await memberRef.get();
  if (!memberSnapshot.exists) {
    await memberRef.set({
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      emailVerified: user.emailVerified,
      emailOptIn: false,
      waiverSigned: false,
      lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
      createdate: firebase.firestore.Timestamp.fromDate(new Date()),
      lastupdate: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  } else {
    await memberRef.update({
      lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  }

  return memberRef;
};

// Events and Tickets
const createInstanceIdentifier = (instance) => {
  const startDate = new Date(instance.startDate.seconds * 1000);
  return `${startDate.getFullYear()}-${
    startDate.getMonth() + 1
  }-${startDate.getDate()}`;
};

const generateTicketSKU = (ticket, instance) => {
  const instanceIdentifier = createInstanceIdentifier(instance);
  return `${ticket.id}-${instanceIdentifier}`;
};
/** EVENTS */
export const getEvent = async (eventSlug) => {
  try {
    const eventRef = db.collection(`clubs/${CLUB_ID}/events`).doc(eventSlug);
    const eventSnapshot = await eventRef.get();

    if (!eventSnapshot.exists) {
      console.error(`Event with slug '${eventSlug}' not found.`);
      return { error: true, message: "Event not found" };
    }

    const eventData = eventSnapshot.data();
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    // Fetch event instances
    const instancesSnapshot = await db
      .collection(`clubs/${CLUB_ID}/eventInstances`)
      .where("eventId", "==", eventSlug)
      .where("startDate", ">", now)
      .orderBy("startDate")
      .limit(1)
      .get();

    if (!instancesSnapshot.empty) {
      const nextInstance = instancesSnapshot.docs[0].data();
      eventData.nextInstance = nextInstance;
      eventData.startDate = nextInstance.startDate;
      eventData.endDate = nextInstance.endDate;
    } else {
      console.error("No future instances found for the event.");
      eventData.nextInstance = null;
    }

    eventData.tickets =
      eventData.tickets?.map((ticket) => ({
        ...ticket,
        sku: generateTicketSKU(ticket, eventData.nextInstance),
        eventId: eventData.id,
        eventName: eventData.name,
        startDate: eventData.nextInstance?.startDate,
        endDate: eventData.nextInstance?.endDate,
      })) || [];

    return eventData;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getEvents = async () => {
  try {
    const eventsRef = db.collection(`clubs/${CLUB_ID}/events`);
    const events = await eventsRef.get();
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const eventData = await Promise.all(
      events.docs.map(async (event) => {
        const eventId = event.id;
        const eventInfo = event.data();

        // Fetch the next instance for this event
        const instancesSnapshot = await db
          .collection(`clubs/${CLUB_ID}/eventInstances`)
          .where("eventId", "==", eventId)
          .where("startDate", ">", now)
          .orderBy("startDate")
          .limit(1)
          .get();

        if (!instancesSnapshot.empty) {
          const nextInstance = instancesSnapshot.docs[0].data();
          return {
            id: eventId,
            ...eventInfo,
            nextInstance,
          };
        }
        return null;
      })
    );

    // Filter out events with no future instances
    const filteredEvents = eventData.filter((event) => event !== null);

    // Sort by the next future instance start date
    const sortedEvents = filteredEvents.sort((a, b) => {
      return a.nextInstance.startDate - b.nextInstance.startDate;
    });

    return sortedEvents;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

// Club Config
export const getDiscount = async (code) => {
  try {
    const discounts = await db
      .collection(`clubs/${CLUB_ID}/config/v1/discounts`)
      .where("code", "==", code.toLowerCase())
      .get();
    const discount = discounts.docs[0];
    if (!discount.exists) {
      return { error: true, message: "Invalid Discount Code" };
    }
    return discount.data();
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getClubInfo = async () => {
  try {
    const clubCollection = db.collection(`clubs/${CLUB_ID}/config/v1/info`);
    const address = await clubCollection.doc("address").get();
    return {
      address: address.data(),
    };
  } catch (error) {
    console.error({ error });
  }
};

export const getClubProducts = async () => {
  try {
    const productRef = db.collection(`clubs/${CLUB_ID}/config/v1/products`);
    const products = await productRef.get();
    const productData = products.docs.reduce((acc, product) => {
      const { category } = product.data();
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({
        id: product.id,
        ...product.data(),
      });
      return acc;
    }, {});
    return productData;
  } catch (error) {
    console.error({ error });
    return {};
  }
};

export const getClubPassTemplates = async () => {
  try {
    const templateRef = db.collection(
      `clubs/${CLUB_ID}/config/v1/passtemplates`
    );
    const templates = await templateRef.get();
    const templateData = templates.docs
      .map((template) => ({
        id: template.id,
        ...template.data(),
      }))
      .sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });
    return templateData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

export const getOrder = async (orderId) => {
  try {
    const orderRef = db.collection(`clubs/${CLUB_ID}/orders`).doc(orderId);
    const order = await orderRef.get();
    return order.data();
  } catch (error) {
    console.error({ error });
    return {};
  }
};

export const getInvite = async (invitation) => {
  try {
    const inviteRef = db.collection(`clubs/${CLUB_ID}/invites`).doc(invitation);
    const invite = (await inviteRef.get()).data();
    return invite;
  } catch (error) {
    console.error({ error });
    return { error: true, message: "Invalid Invitation" };
  }
};

const validateEmail = (email) => {
  return !!email && email.includes("@") && email.length > 5;
};

export const claimPassByLink = async (linkId) => {
  try {
    const claimed = await httpsCallable(
      functions,
      "claimPassByLink"
    )({
      linkId,
      clubId: CLUB_ID,
    });
    return claimed;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const getSharedPasses = async (linkId) => {
  try {
    const sharedPasses = await httpsCallable(
      functions,
      "getSharedPasses"
    )({
      linkId,
      clubId: CLUB_ID,
    });
    return sharedPasses;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const sharePassesWithLink = async (passes, message) => {
  if (passes.length === 0) {
    return {
      error: true,
      message: "Please select at least one pass to share.",
    };
  }

  try {
    const shared = await httpsCallable(
      functions,
      "sharePassesWithLink"
    )({
      passes,
      message,
      clubId: CLUB_ID,
    });
    return shared;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const sharePass = async (passId, email) => {
  if (!validateEmail(email)) {
    return { error: true, message: "Please enter a valid email address" };
  }

  try {
    const shared = await httpsCallable(
      functions,
      "sharePass"
    )({
      passId,
      email,
      clubId: CLUB_ID,
    });
    return shared;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};
export const claimPass = async (inviteId) => {
  try {
    const claimed = await httpsCallable(
      functions,
      "claimPass"
    )({
      inviteId,
      clubId: CLUB_ID,
    });
    return claimed;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const checkin = async (passId) => {
  try {
    const checkedIn = await httpsCallable(
      functions,
      "createCheckin"
    )({
      passId,
      clubId: CLUB_ID,
    });
    return checkedIn;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const createBudPass = async () => {
  const localStorageKey = "budPassCreation";
  try {
    // Check local storage for a cached response
    const cachedData = localStorage.getItem(localStorageKey);
    if (cachedData) {
      const { created, timestamp } = JSON.parse(cachedData);

      // Check if the cached response is from today
      const cachedDate = new Date(timestamp);
      const currentDate = new Date();
      if (cachedDate.toDateString() === currentDate.toDateString()) {
        return created; // Return cached response if it's from today
      }
    }

    // Create a new bud pass if no valid cached response
    const created = await httpsCallable(
      functions,
      "createBudPass"
    )({
      clubId: CLUB_ID,
    });

    // Cache the new response
    const cacheData = {
      created,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(localStorageKey, JSON.stringify(cacheData));

    return created;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const signOut = () => {
  auth.signOut();
};

export const centsToCurrency = (cents) => {
  return (parseInt(cents, 10) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

// Polls

export const fetchUnansweredQuestion = async () => {
  const userId = auth.currentUser?.uid;
  const answeredQuestions = await db
    .collection(`clubs/${CLUB_ID}/answers`)
    .where("userId", "==", userId)
    .get();

  const answeredQuestionIds = answeredQuestions.docs.map(
    (doc) => doc.data().questionId
  );

  const allQuestions = await db.collection(`clubs/${CLUB_ID}/questions`).get();
  const unansweredQuestion = allQuestions.docs.find(
    (doc) => !answeredQuestionIds.includes(doc.id)
  );

  return unansweredQuestion
    ? { id: unansweredQuestion.id, ...unansweredQuestion.data() }
    : null;
};

export const submitAnswer = async (questionId, selectedOption) => {
  const userId = auth.currentUser?.uid;
  return await db.collection(`clubs/${CLUB_ID}/answers`).add({
    questionId,
    selectedOption,
    userId,
  });
};

export const registerForYardSale = async (
  name,
  phoneOrEmail,
  email,
  items,
  paymentType,
  paymentInfo,
  sellerOptions
) => {
  if (!name || !phoneOrEmail || !paymentInfo) {
    throw new Error("Missing required fields");
  }

  const sellerCode = generateUniqueCode();

  // Clean up sellerOptions to remove any undefined values
  const cleanedSellerOptions = Object.entries(sellerOptions).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  try {
    await db.collection("yardsale").add({
      name,
      phoneOrEmail,
      email,
      items: items || [],
      paymentType,
      paymentInfo,
      sellerCode,
      sellerOptions: cleanedSellerOptions,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return { sellerCode };
  } catch (error) {
    console.error("Error saving document: ", error);
    throw new Error("Error saving document");
  }
};

function generateUniqueCode() {
  return "YS-" + Math.random().toString(36).slice(2, 7).toUpperCase();
}


export const registerForOpenMic = async (name, stageName, email, instagram, facebook, otherSocial) => {
  try {
    const docRef = await db.collection("openMicSignups").add({
      name,
      stageName,
      email,
      instagram,
      facebook,
      otherSocial,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error("Error registering for open mic:", error);
    throw new Error("Failed to register for open mic");
  }
};