import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { CartContext } from "../context/CartContext";
import { AppContext } from "../context/AppContext";
import { centsToCurrency } from "../services/firebase";

const POSSystem = () => {
  const { addToCart, removeFromCart, clearCart, getCartTotal, getCartItems } =
    useContext(CartContext);
  const { clubId } = useContext(AppContext);
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [newItem, setNewItem] = useState({ description: "", amount: "" });
  const [error, setError] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleCustomerInfoChange = (e) => {
    setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
  };

  const handleNewItemChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const handleAddItem = () => {
    if (newItem.description && newItem.amount) {
      addToCart({
        id: Date.now().toString(),
        name: newItem.description,
        amount: parseInt(newItem.amount * 100), // Convert to cents
        quantity: 1,
        productType: "custom",
      });
      setNewItem({ description: "", amount: "" });
    }
  };

  const handleRemoveItem = (item) => {
    removeFromCart(item);
  };

  const handlePayment = async (token) => {
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clubId,
            lineItems: getCartItems(),
            total: getCartTotal(),
            currency: "USD",
            sourceId: token,
            customerInfo,
          }),
        }
      );
      if (response.ok) {
        const order = await response.json();
        setOrderDetails({
          orderId: order.orderId,
          total: getCartTotal(),
          items: getCartItems(),
          customerName: customerInfo.name,
        });
        clearCart();
        setModalOpen(true);
      } else {
        const errorMessage = await response.text();
        setError(`Payment failed: ${errorMessage}`);
      }
    } catch (e) {
      setError("There was an error processing your payment");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setOrderDetails(null);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Point of Sale
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Add Item
        </Typography>
        <TextField
          name="description"
          label="Description"
          value={newItem.description}
          onChange={handleNewItemChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="amount"
          label="Amount"
          type="number"
          value={newItem.amount}
          onChange={handleNewItemChange}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" onClick={handleAddItem} sx={{ mt: 2 }}>
          Add to Cart
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCartItems().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right">
                  {centsToCurrency(item.amount)}
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleRemoveItem(item)}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" gutterBottom>
        Total: {centsToCurrency(getCartTotal())}
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Customer Information
        </Typography>
        <TextField
          name="name"
          label="Name"
          value={customerInfo.name}
          onChange={handleCustomerInfoChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          value={customerInfo.email}
          onChange={handleCustomerInfoChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="phone"
          label="Phone (optional)"
          value={customerInfo.phone}
          onChange={handleCustomerInfoChange}
          fullWidth
          margin="normal"
        />
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <PaymentForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
        cardTokenizeResponseReceived={handlePayment}
      >
        <CreditCard />
      </PaymentForm>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Order Completed Successfully
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Order ID: {orderDetails?.orderId}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Customer: {orderDetails?.customerName}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Total: {centsToCurrency(orderDetails?.total)}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Items:
            </Typography>
            {orderDetails?.items.map((item, index) => (
              <Typography key={index}>
                {item.name}: {centsToCurrency(item.amount)}
              </Typography>
            ))}
            <Button onClick={handleCloseModal} sx={{ mt: 3 }}>
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default POSSystem;
